<template>
  <div class="login" v-wechat-title="title" :style="{ height: bodyHeight + 'px' }">
    <div class="proj_box">
      <div class="proj_title">参与项目</div>
      <div class="proj_name">{{ proj_name }}</div>
    </div>
    <template v-if="isShow">
      <van-form @submit="onSubmit" class="form-container" v-if="isChange">
        <div class="title">
          <div class="login-title">{{ showPassword ? '密码登录' : '验证码登录' }}</div>
          <div class="description">欢迎登录医学健康数据平台</div>
        </div>
        <!-- color="#849EFB" -->
        <div class="form">
          <!-- <van-field v-model="code" type="number" name="code" placeholder="请输入验证码" class="filed">
                          <template #left-icon>
                              <img src="/static/code.png" class="icon" />
                          </template>
                          <template #extra>
                              <span class="fieldRemark" @click="getCode">{{btnText}}</span>
                          </template>
                      </van-field> -->
          <div class="password">
            <van-field v-model="mobile" name="mobile" @click="showHight()" placeholder="请输入手机号码" class="filed">
              <template #left-icon>
                <img src="/static/mobile.png" class="icon" />
              </template>
            </van-field>
            <van-field v-if="showPassword" v-model="password" @click="showHight()" type="password" name="password" placeholder="请输入密码" class="filed">
              <template #left-icon>
                <img src="/static/code.png" class="icon" />
              </template>

              <template #extra>
                <span class="fieldRemark" @click="goforget(false, '密码找回')">忘记密码?</span>
              </template>
            </van-field>
            <mobileCode v-if="!showPassword" ref="mobileCode" :projectId="project_id" :mobile="mobile" :formType="'login'" @changeFormCode="changeFormCode"></mobileCode>
          </div>
        </div>
        <!-- <div class="tips">未注册的手机号验证后自动创建账号</div> -->
        <div style="margin-top: 16px">
          <van-button round block type="info" native-type="submit" class="login_btn" color="#4B99FF">登录</van-button>
        </div>
        <div class="choice">
          <div @click="showPassword = !showPassword">{{ showPassword ? '验证码登录' : '密码登录' }}</div>
          <div v-if="is_close_register === 0" @click="goforget(false, '用户注册')">用户注册</div>
        </div>
      </van-form>
      <changePassWord v-else @goforget="goforget" :title="title"></changePassWord>
    </template>
    <div v-if="outDoctor" class="online-login">
      <van-loading size="24px">登录中...</van-loading>
    </div>
    <!-- 项目已关闭 -->
    <!-- <van-dialog v-model="closed" title="温馨提示" :show-confirm-button="false">
            <van-form class="from" style="margin: 16px 10px;" input-align="left">
            <div class="dialogContent">该项目已关闭</div>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" color="#849EFB" class="up" @click="out()">退出登陆</van-button>
            </div>
            </van-form>
        </van-dialog> -->

    <div class="img">
      <!-- <img src="/static/login.png" /> -->
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { Children } from './login.js'
import { login, loginOut, getList, bindDoctor } from '@/api/api'
import { checkCellphone } from '@/utils/utils'
import changePassWord from './changePassWord.vue'
import mobileCode from '@/components/mobileCode.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  mixins: [Children],
  components: {
    changePassWord,
    mobileCode
  },
  name: '',
  data() {
    return {
      isShow: false,
      outDoctor: localStorage.getItem('outDoctor') || '', // 来自第三方平台医生id
      password: '',
      code: '',
      isChange: true,
      showPassword: true,
      title: '医学健康数据平台',
      project_id: null,
      closed: false, //项目已关闭
      projList: [], //项目列表
      is_close_register: 1, // 当前项目是否关闭注册
      proj_name: '', //项目名称
      bodyHeight: null //页面高度
    }
  },
  mounted() {
    if (!this.outDoctor) this.isShow = true
    console.log('outDoctor', this.outDoctor)
    if (this.$route.query.mobile) {
      this.mobile = this.$route.query.mobile
    }
    if (!localStorage.getItem('project_id')) {
      if (location.hostname.indexOf('.papv2.sungotech.com') >= 0) {
        location.href = 'http://ecase.papwxtest.sungotech.com/?#/menuIndex'
      } else {
        location.href = 'https://ecase.papwx.cpah.net.cn/?#/menuIndex'
      }
      return
    }
    // 防止安卓软键盘压缩背景
    this.bodyHeight = document.documentElement.clientHeight - 45
    console.log('bodyHeight', this.bodyHeight)
    console.log('----login--')
    this.project_id = localStorage.getItem('project_id')
    console.log('this.project_id', this.project_id)
    if (this.registerInfo) this.goforget(false, '用户注册')
    console.log('this.registerInfo', this.registerInfo)
    this.init()
  },
  computed: {
    ...mapState(['registerInfo', 'ifClosed', 'userInfo'])
  },
  methods: {
    ...mapMutations(['setClosePop']),
    ...mapActions(['SET_USER_ID', 'SET_DOCTOR_INFO', 'GET_IF_CLOSED', 'SET_PROJ_NAME']),
    async init() {
      await getList()
        .then(res => {
          console.log('res项目列表inLogin', res)
          this.projList = res
          for (let i in this.projList) {
            if (this.projList[i].id == this.project_id) {
              this.proj_name = this.projList[i].name
              this.is_close_register = this.projList[i].is_close_register
            }
          }
          this.SET_PROJ_NAME(this.proj_name)
          console.log('项目名：', this.proj_name)
        })
        .catch(err => {
          console.log('err', err)
        })
      if (this.outDoctor) {
        try {
          let res = await bindDoctor({
            wechat_user_id: this.userInfo.id,
            doctor_id: this.outDoctor,
            project_id: this.project_id
          })
          console.log('res', res)
          this.GET_IF_CLOSED().then(() => {
            if (this.ifClosed) this.setClosePop(true) //打开项目关闭弹框
          })
          this.SET_USER_ID(res.id)
          this.SET_DOCTOR_INFO(res)
          localStorage.removeItem('outDoctor')
          this.$router.replace({
            path: '/me'
          })
        } catch (err) {
          Toast(err.response.data.message)
          console.log(err)
          console.log('登录失败')
          this.outDoctor = ''
          localStorage.removeItem('outDoctor')
        }
      }
    },
    showHight() {
      console.log('点击输入框，查看页面高度', document.documentElement.clientHeight)
    },
    async onSubmit(values) {
      let data = {}
      if (!this.showPassword) {
        if (!this.mobile || !this.code) {
          Toast('请完善信息')
          return false
        } else if (!checkCellphone(this.mobile)) {
          Toast('请输入正确的手机号码')
          return false
        }
        data = {
          mobile: this.mobile,
          code: this.code,
          projectId: this.project_id
        }
      } else {
        if (!this.mobile || !this.password) {
          Toast('请完善信息')
          return false
        } else if (!checkCellphone(this.mobile)) {
          Toast('请输入正确的手机号码')
          return false
        } else if (this.password.length > 18 || this.password.length < 8) {
          Toast('请输入8-18位数的密码')
          return false
        }
        data = {
          mobile: this.mobile,
          projectPassword: this.password,
          projectId: this.project_id
        }
      }
      try {
        let res = await login(data)

        console.log('res', res)
        this.GET_IF_CLOSED().then(() => {
          if (this.ifClosed) this.setClosePop(true) //打开项目关闭弹框
        })
        this.SET_USER_ID(res.id)
        this.SET_DOCTOR_INFO(res)
        this.$router.replace({
          path: '/me'
        })
      } catch (err) {
        Toast(err.response.data.message)
        console.log(err)
        console.log('登录失败')
      }
    },
    goforget(e, title) {
      if (title == '用户注册' && this.proj_name == '例例生辉病例调研') {
        Dialog.alert({
          title: '温馨提示',
          message: '项目调研已达上限，感谢您的参与',
          confirmButtonColor: '#4B99FF'
        })
        return
      }
      this.isChange = e
      if (e) {
        this.title = '医学健康数据平台'
      } else {
        this.title = title
      }
    },
    changeFormCode(value, field) {
      this.code = value
    }
  }
}
</script>

<style src="./login.scss" lang="scss">
.login {
}
</style>
